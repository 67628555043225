import getAxios from './header';
import axios from 'axios';

export async function getApi(path, baseUrl = process.env.VUE_APP_COMMON_BASE_URL) {
    try {
        var response = await getAxios().get(`${baseUrl}${path}`);
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function postApi(path, payload, baseUrl = process.env.VUE_APP_COMMON_BASE_URL) {

    try {
        var response = await getAxios().post(`${baseUrl}${path}`, payload);
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function deleteApi(path) {
    try {
        var response = await getAxios().delete(`${process.env.VUE_APP_COMMON_BASE_URL + path}`);
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function getServiceApi(path, baseUrl = process.env.VUE_APP_SERVICE_URL) {
    try {
        var response = await getAxios().get(`${baseUrl}${path}`);
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function getNoAuthRFPApi(path, baseUrl = process.env.VUE_APP_RFP_API_URL) {
    try {
        var response = await axios.get(`${baseUrl}${path}`);
        return response;
    } catch (err) {
        return err.response;
    }
}
