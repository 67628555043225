import axios from 'axios';
//import { useStorage } from "vue3-storage";
import CurrentUserService from "../service/CurrentUserService";
import { getAccountId } from './common';
const currentUser = new CurrentUserService();

function getAxios() {

	currentUser.refreshCognitoJwt();

	//const storage = useStorage();
	let jwt = currentUser.jwt;

	const instance = axios.create({
		baseURL: process.env.VUE_APP_DOT_NET_BASE_URL,
        withCredentials: false
	});

	// set common headers
	instance.defaults.headers.common['Authorization'] = jwt;
	instance.defaults.headers.common['Content-Type'] = 'application/json';
	
	return instance;
}

export default class MarketplaceApiService {

	async getMemberInvoices() {
		const accountId = getAccountId();

		console.log('Inside MarketplaceApiService.getMemberInvoices()');
		var result = await getAxios().get(`/Account/${accountId}/invoices`)
		.then(res => 
			{
				return res.data;
			})
		.catch(err => {
			console.log("ERROR TIME");
			console.log(err);
		});
	
		return result;
	}

    
	async getPartnerBills() {
		const accountId = getAccountId();

		console.log('Inside MarketplaceApiService.getPartnerBills()');
		var result = await getAxios().get(`/Account/${accountId}/bills`)
		.then(res => 
			{
				return res.data;
			})
		.catch(err => {
			console.log("ERROR TIME");
			console.log(err);
		});
	
		return result;
	}

    
    
	async getAccountDetails() {
		const accountId = getAccountId();

		console.log('Inside MarketplaceApiService.getAccountDetails()');
		return getAxios().get(`/Account/${accountId}`)
			.then(res => res.data)
			.catch(err => {
				console.log("ERROR TIME");
				console.log(err);
			});
	}

	async getUserDetails(cognitoUsername) {
		console.log('Inside MarketplaceApiService.getUserDetails()');
		
		return await getAxios().get(`/Users/${cognitoUsername}`)
			.then(res => res.data)
			.catch(err => {
				console.log("ERROR TIME");
				console.log(err);
			});
	}   
}
