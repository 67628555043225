<template>
    <div class="pages-body login-page p-d-flex p-flex-column login-bg-color">
        <div class="p-as-center p-mt-auto p-mb-auto">
            <div class="pages-panel p-d-flex p-flex-column">
                <div class="logo">
                    <img
                        id="logo"
                        src="assets/layout/images/arenacx_white_logo.png"
                        alt="ultima-layout"
                        style="height: 6.25rem"
                    />
                </div>
                <form>
                    <amplify-authenticator username-alias="email">
                        <amplify-sign-in
                            slot="sign-in"
                            username-alias="email"
                            :formFields="loginFormFields"
                            hideSignUp
                        ></amplify-sign-in>
                    </amplify-authenticator>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
//import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth, Hub } from 'aws-amplify';
import CurrentUserService from '../service/CurrentUserService';
import MarketplaceApiService from '../service/MarketplaceApiService';

export default {
    name: 'AuthStateApp',
    data() {
        return {
            user: undefined,
            authState: undefined,
            unsubscribeAuth: undefined,
            signedIn: true,
            errorMessage: '',
            displayErrorMessage: false,
            loginFormFields: [
                {
                    type: 'email',
                    label: 'Username/Email',
                    placeholder: 'Enter email',
                    inputProps: { required: true, autocomplete: 'username' },
                },
                {
                    type: 'password',
                    label: 'Password',
                    placeholder: 'Enter password',
                    inputProps: { required: true, autocomplete: 'current-password' },
                },
            ],
        };
    },
    created() {
        window.localStorage.removeItem('accountId');
    },
    beforeCreate() {
        Hub.listen(
            'auth',
            async (data) => {
                switch (data.payload.event) {
                    case 'signIn':
                        await this.prepareAxios(data.payload);
                        this.displayErrorMessage = false;
                        console.log('user signed in');
                        break;
                    case 'signOut':
                        console.log('user signed out');
                        this.$router.push('/login');
                        this.signedIn = true;
                        break;
                    case 'signIn_failure':
                        console.log('user sign in failed');
                        this.errorMessage = data.payload.data.message;
                        console.error('error msg', data.payload);
                        this.displayErrorMessage = true;
                        break;
                    case 'configured':
                        console.log('the Auth module is configured');
                }
            },

            Auth.currentAuthenticatedUser()
                .then(() => {
                    this.signedIn = true;
                })
                .catch(() => (this.signedIn = false))
        );
    },
    methods: {
        async prepareAxios(authPayload) {
            // Prepare axios for all API calls to Marketplace API
            const userService = new CurrentUserService();

            let cognitoUsername = authPayload.data.username;
            let jwtToken = authPayload.data.signInUserSession.idToken.jwtToken;
            let refreshToken = authPayload.data.signInUserSession.refreshToken.token;

            userService.jwt = jwtToken;

            userService.refreshToken = refreshToken;

            this.apiService = new MarketplaceApiService();
            let marketPlaceUser = await this.apiService.getUserDetails(cognitoUsername);
            userService.marketplaceUser = marketPlaceUser;

            if (userService.marketplaceUser.userRole.roleKey.startsWith('acx')) {
                this.$router.push('/');
            } else if (userService.marketplaceUser.userRole.roleKey.startsWith('partner')) {
                window.localStorage.setItem('accountId', userService.marketplaceUser.accountId);
                this.$router.push(`/partner/home`);
            } else if (userService.marketplaceUser.userRole.roleKey.startsWith('member')) {
                window.localStorage.setItem('accountId', userService.marketplaceUser.accountId);
                this.$router.push(`/member/home`);
            }
        },
    },
};
</script>

<style scoped>
amplify-authenticator {
    /* background: #e5e5e5; */

    --amplify-primary-color: #fd705e;
    --amplify-primary-tint: #fd705e;
    --amplify-primary-shade: #fd705e;

    /* --amplify-background-color: linear-gradient(135deg, #0F5B60, #57be92); */
    --container-display: inline;
    display: var(--container-display);
    min-height: 0;
}

.login-bg-color {
    background: #0f5b60;
    /* background: linear-gradient(135deg, #0F5B60, #E4EDD9, #57BE92); */
}

.login-logo-color {
    /* background: linear-gradient(180deg, #0f5b60, #e4edd9, #57be92); */
    background-color: #0f5b60;
}

/* :root {
    --amplify-primary-color: #57be92;
    --amplify-primary-tint: #0f5b60;
    --amplify-primary-shade: #0f5b60;
} */
</style>
