export const directoryCode = {
    PARTNER: 0,
    MARKETING: 1,
    TALENT: 2,
    TECH: 3,
    TRANSFORM: 4,
};

export const directoryOptions = [
    { name: 'Talent', code: 2 },
    { name: 'Tech', code: 3 },
    { name: 'Transform', code: 4 },
];

export const directoryTree = [
    {
        key: '-1',
        label: 'Talent',
        data: 'Talent',
        children: [
            {
                key: 'Talent',
                descriptionHtml:
                    '<p>Fill out this form to describe your Business Process Outsourcing talents, experience and capabilities.  This allows us to match you to the best incoming opportunities.</p>',
                label: 'Talent',
                data: 2,
                icon: 'pi pi-fw pi-star',
            },
        ],
    },
    {
        key: 'Technology',
        label: 'Technology',
        data: 'Technology',
        icon: null, //"pi pi-fw pi-inbox",

        children: [
            {
                key: 'Technology:Software',
                label: 'Software',
                data: 'Software Products',
                icon: null, //"pi pi-fw pi-cog",
                children: [
                    {
                        key: 'Technology:Software:Customer Service & Engagement',
                        label: 'Customer Service & Engagement',
                        data: 12,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Contact Center as a Service',
                        label: 'Contact Center as a Service',
                        data: 13,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Conversational AI & Chatbots',
                        label: 'Conversational AI & Chatbots',
                        data: 14,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Quality, Training, Agent Assist',
                        label: 'Quality, Training, Agent Assist',
                        data: 15,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Customer & Agent Analytics',
                        label: 'Customer & Agent Analytics',
                        data: 16,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Customer Relationship Management',
                        label: 'Customer Relationship Management',
                        data: 17,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Video, Telephony, Carriers',
                        label: 'Video, Telephony, Carriers',
                        data: 18,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Contact Center Accessibility',
                        label: 'Contact Center Accessibility',
                        data: 19,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Customer Feedback',
                        label: 'Customer Feedback',
                        data: 20,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Workforce Management',
                        label: 'Workforce Management',
                        data: 21,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                    {
                        key: 'Technology:Software:Contact Center Compliance, Reputation, Security',
                        label: 'Contact Center Compliance, Reputation, Security',
                        data: 22,
                        icon: 'pi pi-fw pi-star',
                        style: {},
                        descriptionHtml: '',
                    },
                ],
            },
            {
                key: 'Technology:Hardware',
                label: 'Hardware',
                data: 10,
                icon: 'pi pi-fw pi-star',
                style: {},
                descriptionHtml: '',
            },
        ],
    },
    {
        key: 'Transformation',
        label: 'Transformation',
        data: 'Transformation Directory',
        icon: null, //"pi pi-fw pi-inbox",

        children: [
            {
                key: 'Transformation:Consulting',
                label: 'Consulting',
                data: 5,
                icon: 'pi pi-fw pi-star',
                style: {},
                descriptionHtml: '',
            },
            {
                key: 'Transformation:Systems Integration',
                label: 'Systems Integration',
                data: 6,
                icon: 'pi pi-fw pi-star',
                style: {},
                descriptionHtml: '',
            },
            {
                key: 'Transformation:Managed Services',
                label: 'Managed Services',
                data: 7,
                icon: 'pi pi-fw pi-star',
                style: {},
                descriptionHtml: '',
            },
            {
                key: 'Transformation:Recruiting',
                label: 'Recruiting',
                data: 8,
                icon: 'pi pi-fw pi-star',
                style: {},
                descriptionHtml: '',
            },
            {
                key: 'Transformation:Training',
                label: 'Training',
                data: 4,
                icon: 'pi pi-fw pi-star',
                style: {},
                descriptionHtml: '',
            },
        ],
    },
];
