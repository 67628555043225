<template>
    <ul v-if="itemList" role="menu">
        <template v-for="(item, i) of itemList">
            <li
                v-if="visible(item) && !item.separator"
                :key="item.label || i"
                :class="[{ 'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !item.disabled }]"
                role="menuitem"
            >
                <div
                    v-if="root && isAdmin"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                    @click="toggleCollapse(i, item)"
                >
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <span class="p-badge p-component p-badge-no-gutter" :class="item.badgeStyleClass" v-if="item.badge">{{
                        item.badge
                    }}</span>
                    <i
                        v-if="item.items"
                        class="pi pi-fw layout-submenu-toggler"
                        :class="{
                            'pi-angle-down': isCollapsed(i),
                            'pi-angle-up': !isCollapsed(i),
                        }"
                    ></i>
                </div>

                <router-link
                    v-if="item.to"
                    :to="{ path: item.to }"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                    :active-class="isActiveClass(item) ? 'active-route' : ''"
                    :exact-active-class="isActiveClass(item) ? 'router-link-exact-active' : ''"
                    @mouseenter="onMenuItemMouseEnter(i)"
                    @mouseleave="onMenuItemMouseLeave"
                    @click="
                        onMenuItemClick($event, item, i);
                        toggleCollapse(i, item);
                    "
                >
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <span
                        class="p-badge p-component p-badge-no-gutter"
                        :class="item.badgeStyleClass"
                        v-if="item.badge && !root"
                        >{{ item.badge }}</span
                    >
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                </router-link>
                <a
                    v-if="!item.to"
                    :href="item.url || '#'"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                    :target="item.target"
                    @click="
                        onMenuItemClick($event, item, i);
                        toggleCollapse(i, item);
                    "
                    @mouseenter="onMenuItemMouseEnter(i)"
                    @mouseleave="onMenuItemMouseLeave"
                    v-ripple
                >
                {{ searchQuery }}
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <span
                        class="p-badge p-component p-badge-no-gutter"
                        :class="item.badgeStyleClass"
                        v-if="item.badge && !root"
                        >{{ item.badge }}</span
                    >
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                </a>
                <span class="layout-menuitem-tooltip p-tooltip">
                    <span class="layout-menuitem-tooltip-arrow p-tooltip-arrow"></span>
                  
                    <span class="layout-menuitem-tooltip-text p-tooltip-text">{{ item.label }}</span>
                </span>
                <transition name="layout-menu">
                    <appsubmenu
                        v-show="
                            !isAdmin ||
                            (!root && searchQuery) ||
                            (item.items &&
                                (!isSlim() || (isSlim() && (mobileMenuActive || activeIndex !== null))
                                    ? true
                                    : activeIndex === i) &&
                                isItemExpanded(i))
                        "
                        :items="visible(item) && item.items"
                        @menuitem-click="$emit('menuitem-click', $event)"
                        :menuMode="menuMode"
                        :menuActive="menuActive"
                        :parentMenuItemActive="activeIndex === i"
                    ></appsubmenu>
                </transition>
            </li>
            <li
                class="p-menu-separator"
                :style="item.style"
                v-if="visible(item) && item.separator"
                :key="'separator' + i"
                role="separator"
            ></li>
        </template>
    </ul>
</template>
<script>
import EventBus from './event-bus';
import { getAccountId, setAccountId } from '../src/service/common/index';

var selectedCompanyName = null;
var selectedDashboardLabel = null;
var pageTrackInfoData = null;
var selectedMenuLabel = null;

window.setTimeout(function () {
    window.onpopstate = () => {
        this.pageTrackGA(this.selectedItem);
    };
}, 1);
window.setTimeout(function () {
    window.onpoonbeforeunloadpstate = () => {
        this.pageTrackGA(this.selectedItem);
    };
}, 1);

export default {
    name: 'appsubmenu',
    emits: ['menuitem-click', 'root-menuitem-click'],
    props: {
        items: Array,
        root: {
            type: Boolean,
            default: false,
        },
        menuActive: {
            type: Boolean,
            default: true,
        },
        parentMenuItemActive: {
            type: Boolean,
            default: false,
        },
        menuMode: String,
        mobileMenuActive: Boolean,
        isSlimOrHorItemClick: Boolean,
        searchQuery: {
        type: String,
        default: '', // Default to an empty string
    },
    },
    mounted() {
       
        EventBus.on('reset-active-index', () => {
            if (this.isHorizontalOrSlim() && !this.isMobile()) {
                this.activeIndex = null;
            }
        });
    },
    created() {
        // Ensure localSearchQuery is set based on the prop value upon creation
        this.localSearchQuery = this.searchQuery; // Set it here
    },
    data() {
        return {
            activeIndex: null,
            hoverMenuActive: false,
            itemList: this.items,
            selectedItem: null,
            lastClicked: new Date().getTime(),
            count: 0,
            expandedItems: {},
            localSearchQuery : this.searchQuery || ''
        };
    },
  

    watch: {
        
        items() {
            this.itemList = this.items;
        },
        searchQuery: {
            immediate: true, // This ensures the watcher runs as soon as the component is created
            handler(newVal) {
                this.localSearchQuery = newVal; // Update localSearchQuery only from the watch
            },
        },
    },
    methods: {
        isCollapsed(index) {
            // If there's a search query, keep items expanded
        if (this.searchQuery) {
            return false;
        }
            return !this.expandedItems[index]; // If you want the opposite of isItemExpanded
        },
        toggleCollapse(index, item) {
            // Toggle the expanded state of the clicked item
            this.expandedItems[index] = !this.expandedItems[index];
        },
        isItemExpanded(index) {
              // If there's a search query, keep items expanded
        if (this.searchQuery) {
            return true;
        }
            return this.expandedItems[index] === true;
        },

        isActiveClass(item) {
            const activeClassStyle = getAccountId();
            if (item.accountId) {
                if (activeClassStyle === item.accountId) {
                    return true;
                }
                return false;
            }
            return true;
        },
        pageTrackGA() {
            const timeNow = new Date().getTime();
            if (timeNow > this.lastClicked) {
                const duration = timeNow - this.lastClicked;
                const seconds = Math.floor(duration / 1000);
                this.lastClicked = timeNow;

                let pageTrackInfo = {};
                pageTrackInfo.time_spend = seconds;
                pageTrackInfo.company_name = selectedCompanyName;
                pageTrackInfo.page_label = selectedMenuLabel;
                pageTrackInfo.timestamp = timeNow;
                if (selectedDashboardLabel !== null) pageTrackInfo.dashboard_name = selectedDashboardLabel;
                pageTrackInfoData = pageTrackInfo;
            }
        },
        onMenuItemClick(event, item, index) {
            if (item.items) {
                if (item.icon === 'pi pi-fw pi-star') selectedDashboardLabel = item.label;
                else if (item.icon === 'pi pi-fw pi-globe') selectedCompanyName = item.label;
            } else {
                pageTrackInfoData && this.$gtag.event('page_track', pageTrackInfoData);
                selectedMenuLabel = item.label;
                if (!selectedCompanyName) selectedCompanyName = localStorage.getItem('companyName');
                this.pageTrackGA();
            }

            if (item.disabled) {
                event.preventDefault();
                return;
            }

            //alert(JSON.stringify(event));

            //execute command
            if (item.command) {
                item.command({ originalEvent: event, item: item });
                event.preventDefault();
            }

            if (item.accountId) {
                this.itemList = Object.assign({}, this.items);
                setAccountId(item.accountId);
            }

            if (item.items) {
                event.preventDefault();
            } else {
                if (this.isHorizontalOrSlim()) {
                    this.hoverMenuActive = false;
                }

                if (this.menuMode !== 'static') {
                    const ink = this.getInk(event.currentTarget);
                    if (ink) {
                        this.removeClass(ink, 'p-ink-active');
                    }
                }
            }

            if (this.root) {
                this.hoverMenuActive = !this.hoverMenuActive;

                this.$emit('root-menuitem-click', {
                    originalEvent: event,
                    isSameIndex: index === this.activeIndex,
                });
            }

            if (item.items) {
                this.activeIndex = index === this.activeIndex ? null : index;
            }

            this.$emit('menuitem-click', {
                originalEvent: event,
                item: item,
            });
        },
        onMenuItemMouseEnter(index) {
            if (this.isSlimOrHorItemClick) {
                this.hoverMenuActive = true;
            }

            if (this.root && this.hoverMenuActive && this.isHorizontalOrSlim() && !this.isMobile()) {
                this.activeIndex = index;
            }
        },
        onMenuItemMouseLeave() {
            this.hoverMenuActive = false;
        },
        isHorizontalOrSlim() {
            return this.menuMode === 'horizontal' || this.menuMode === 'slim';
        },
        isMobile() {
            return window.innerWidth <= 640;
        },
        visible(item) {
            return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
        },
        getInk(el) {
            for (let i = 0; i < el.children.length; i++) {
                if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
                    return el.children[i];
                }
            }
            return null;
        },
        removeClass(element, className) {
            if (element.classList) {
                element.classList.remove(className);
            } else {
                element.className = element.className.replace(
                    new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
                    ' '
                );
            }
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
    },
    computed: {
        isAdmin() {
            return localStorage.getItem('isAdmin') === 'true';
        },
    },
};
</script>

<style scoped></style>
