import axios from 'axios';
import CurrentUserService from '../CurrentUserService';
const currentUser = new CurrentUserService();

export default function getAxios() {
    currentUser.refreshCognitoJwt();

    //const storage = useStorage();
    let jwt = currentUser.jwt;

    const instance = axios.create({
        baseURL: process.env.VUE_APP_DOT_NET_BASE_URL,
        withCredentials: false,
    });

    // set common headers
    instance.defaults.headers.common['Authorization'] = jwt;
    instance.defaults.headers.common['Content-Type'] = 'application/json';

    return instance;
}
