<template>
    <AppSubmenu
        class="layout-menu"
        :items="model"
        :menuMode="menuMode"
        :menuActive="active"
        :isSlimOrHorItemClick="isSlimOrHorItemClick"
        :root="true"
        :parentMenuItemActive="true"
        :mobileMenuActive="mobileMenuActive"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
		:searchQuery="searchQuery"
    />
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', ],
    props: {
        model: Array,
        menuMode: String,
        active: Boolean,
        mobileMenuActive: Boolean,
        isSlimOrHorItemClick: Boolean,
        searchQuery: {
        type: String,
        default: '', // Default to an empty string
    },
    },
    components: {
        AppSubmenu: AppSubmenu,
    },
    mounted() {
        console.log('Initial searchQuery:', this.searchQuery); // Log the initial searchQuery
    },
    watch: {
        searchQuery(newVal) {
            console.log('Updated searchQuery:', newVal); // Log the updated searchQuery
        },
    },
    methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
};
</script>

<style scoped></style>
