import { postApi, getServiceApi, getNoAuthRFPApi } from './common/apis';

export default class AccountMangementService {
    async addNewUser(payload) {
        var userResponse = await postApi(`v1/account`, payload);
        return userResponse;
    }
    async getChannels() {
        const subnetworkData = await getServiceApi(`/api/v1/get-subnetwork`);
        return subnetworkData;
    }
    async getContractTemplate(subnetworkId) {
        const contractTemplateData = await getNoAuthRFPApi(`api/v1/check-channel-template/${subnetworkId}`);
        return contractTemplateData;
    }
}
