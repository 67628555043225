<template>
    <div>
        Old Landing page
    </div>

</template>

<script>
export default {
    data() {
        return {
            isMenuActive: false
        }
    },
    methods:  {
        
    }
}
</script>

<style lang="scss" scoped>

</style>