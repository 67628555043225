import { getAccountId } from './common';
import getAxios from './common/header';
import { postApi, getApi } from './common/apis';
import { directoryTree } from './common/directoryCode';

export default class AnswerBankService {
    async getTemplates() {
        try {
            var response = await getApi(`v1/template/answer-bank`, process.env.VUE_APP_ANSWER_BANK_SERVICE_URL);
            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    async getTemplateById(id, admin = false) {
        try {
            let isAdmin = localStorage.getItem('isAdmin') ?? admin;
            var response = await getApi(
                `v2/template/answer-bank/?qsid=${id}&isAdmin=${isAdmin}`,
                process.env.VUE_APP_ANSWER_BANK_SERVICE_URL
            );
            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    async getAnswers(qsid) {
        const accountId = getAccountId();
        try {
            var response = await getApi(
                `v2/answer-bank/?id=${accountId}&qsid=${qsid}`,
                process.env.VUE_APP_ANSWER_BANK_SERVICE_URL
            );
            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    async postAnswers(payload, qsid) {
        const accountId = getAccountId();
        var answerBankQuestions = await getAxios().post(
            `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v2/answer-bank?id=${accountId}&qsid=${qsid}`,
            JSON.parse(JSON.stringify(payload))
        );
        return answerBankQuestions;
    }

    async getAnswerBankAccess() {
        const accountId = getAccountId();
        try {
            var response = await getApi(`v2/answer-bank/access?id=${accountId}`, process.env.VUE_APP_ANSWER_BANK_SERVICE_URL);
            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    async postAnswerBankAccess(payload) {
        const accountId = getAccountId();
        try {
            var response = await getAxios().post(
                `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v2/answer-bank/access?id=${accountId}`,
                JSON.parse(JSON.stringify(payload))
            );
            return response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    async getDirectoryTree() {
        const accountId = getAccountId();
        try {
            var response = await getApi(`v3/answer-bank/directory?id=${accountId}`);
            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    getDirectoryTreeData() {
        let data = directoryTree;
        // console.log(data);
        return data;
    }
}
