import { createRouter, createWebHashHistory } from 'vue-router';
import { Auth } from 'aws-amplify';

const routes = [
    {
        path: '/',
        name: 'acxhome',
        exact: true,
        component: () => import('./components/AcxHome.vue'),
        meta: {
            breadcrumb: [{ label: 'ACX Home' }],
        },
    },
    {
        path: '/member/home',
        name: 'memberhome',
        exact: true,
        component: () => import('./components/MemberHome.vue'),
        meta: {
            breadcrumb: [{ label: 'Member Home' }],
        },
    },
    {
        path: '/partner/home',
        name: 'partnerhome',
        exact: true,
        component: () => import('./components/PartnerHome.vue'),
        meta: {
            breadcrumb: [{ label: 'Partner Home' }],
        },
    },
    {
        path: '/partner/opportunities',
        name: 'opportunities',
        exact: true,
        component: () => import('./pages/Opportunities.vue'),
        meta: {
            breadcrumb: [{ label: 'Opportunities' }],
        },
    },
    {
        path: '/partner/profile',
        name: 'partnerprofile',
        exact: true,
        component: () => import('./components/partnerProfile/Profile.vue'),
        meta: {
            breadcrumb: [{ label: 'Partner Profile' }],
        },
    },
    {
        path: '/partner/users',
        name: 'partnerusers',
        exact: true,
        component: () => import('./components/users/Users.vue'),
        meta: {
            breadcrumb: [{ label: 'Partner Users' }],
        },
    },
    {
        path: '/account/:accountId?/dashboarddemo',
        name: 'dashboarddemo',
        exact: true,
        component: () => import('./pages/DashboardDemo.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/partner/answerbank',
        name: 'answerbank',
        exact: true,
        component: () => import('./pages/AnswerBank.vue'),
        meta: {
            breadcrumb: [{ label: 'Answer Bank' }],
        },
    },
    {
        path: '/partner/answerbank-talent',
        name: 'answerbank-talent',
        exact: true,
        component: () => import('./pages/AnswerBankTalent.vue'),
        meta: {
            breadcrumb: [{ label: 'Answer Bank - Talent' }],
        },
    },
    {
        path: '/partner/answerbank-tech',
        name: 'answerbank-tech',
        exact: true,
        component: () => import('./pages/AnswerBankTech.vue'),
        meta: {
            breadcrumb: [{ label: 'Answer Bank - Tech' }],
        },
    },
    {
        path: '/partner/answerbank-transform',
        name: 'answerbank-transform',
        exact: true,
        component: () => import('./pages/AnswerBankTransform.vue'),
        meta: {
            breadcrumb: [{ label: 'Answer Bank - Transform' }],
        },
    },
    {
        path: '/partner/marketing',
        name: 'marketing',
        exact: true,
        component: () => import('./components/marketing/Marketing.vue'),
        meta: {
            breadcrumb: [{ label: 'Marketing Profile' }],
        },
    },
    // {
    //     path: '/member/partnerselector',
    //     name: 'partnerselector',
    //     exact: true,
    //     props: true,
    //     component: () => import('./pages/PartnerSelectors.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Partner Selector' }],
    //     },
    // },
    // {
    //     path: '/member/partnerselector/filter',
    //     name: 'partnerselectorfilter',
    //     exact: true,
    //     props: true,
    //     component: () => import('./components/partnerSelector/FilterPartner.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Partner Selector' }],
    //     },
    // },
    // {
    //     path: '/member/partnerselector/comparison',
    //     name: 'partnerselectorcomparison',
    //     exact: true,
    //     props: true,
    //     component: () => import('./components/partnerSelectorComparison/PartnerSelectorComparison.vue'),
    //     meta: {
    //         breadcrumb: [{ label: 'Partner Selector' }],
    //     },
    // },
    // {
    //     path: '/member/partnerselector/view',
    //     name: 'viewShortlistPartners',
    //     exact: true,
    //     props: true,
    //     component: () => import('./components/partnerSelector/ViewShortlistPartners.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Partner Selector' }],
    //     },
    // },
    // {
    //     path: '/partnerdetails',
    //     name: 'viewPartnerDetails',
    //     exact: true,
    //     props: true,
    //     component: () => import('./components/partnerSelector/PartnerDetails.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Partner Details' }],
    //     },
    // },
    {
        path: '/favorites/dashboardanalytics',
        name: 'dashboardanalytics',
        exact: true,
        component: () => import('./components/DashboardAnalytics.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard Analytics' }],
        },
    },
    {
        path: '/favorites/domodashboardview/:dashType?/:domoEmbedId?',
        name: 'domodashboardview',
        exact: true,
        component: () => import('./components/DomoDashboardView.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/account/:accountId?/invoices',
        name: 'invoices',
        component: () => import('./components/MemberInvoiceView.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Invoices' }],
        },
    },
    {
        path: '/account/:accountId?/documents',
        name: 'documents',
        component: () => import('./pages/Documents.vue'),
        meta: {
            breadcrumb: [{ label: 'Documents' }],
        },
    },
    {
        path: '/account/:accountId?/billing',
        name: 'billing',
        component: () => import('./components/PartnerBillView.vue'),
        meta: {
            breadcrumb: [{ parent: 'Account', label: 'Billing' }],
        },
    },
    {
        path: '/userprofile',
        name: 'userprofile',
        component: () => import('./pages/UserProfile.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [{ label: 'User Profile' }],
        },
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
        },
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
        },
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        },
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
        },
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
        },
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
        },
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
        },
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
        },
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
        },
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlaysDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
        },
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
        },
    },
    {
        path: '/menus',
        component: () => import('./components/MenusDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menus/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
        },
    },

    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
        },
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
        },
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./utilities/Icons.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
        },
    },
    {
        path: '/widgets',
        name: 'widgets',
        component: () => import('./utilities/Widgets.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
        },
    },
    {
        path: '/grid',
        name: 'grid',
        component: () => import('./utilities/GridDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
        },
    },
    {
        path: '/spacing',
        name: 'spacing',
        component: () => import('./utilities/SpacingDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
        },
    },
    {
        path: '/elevation',
        name: 'elevation',
        component: () => import('./utilities/ElevationDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
        },
    },
    {
        path: '/typography',
        name: 'typography',
        component: () => import('./utilities/Typography.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
        },
    },
    {
        path: '/display',
        name: 'display',
        component: () => import('./utilities/DisplayDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
        },
    },
    {
        path: '/flexbox',
        name: 'flexbox',
        component: () => import('./utilities/FlexBoxDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
        },
    },
    {
        path: '/text',
        name: 'text',
        component: () => import('./utilities/TextDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
        },
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('./pages/Invoice.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('./pages/Help.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Help' }],
        },
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
        },
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/Landing.vue'),
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: () => import('./pages/ContactUs.vue'),
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('./pages/MyContacts.vue'),
        meta: {
            breadcrumb: [{ parent: 'Collaboration', label: 'Contacts' }],
        },
    },
    {
        path: '/videomeeting',
        name: 'videomeeting',
        component: () => import('./pages/VideoMeeting.vue'),
        meta: {
            breadcrumb: [{ parent: 'Collaboration', label: 'Video Meeting' }],
        },
    },
    {
        path: '/messagecenter',
        name: 'messagecenter',
        component: () => import('./pages/MessageCenter.vue'),
        meta: {
            breadcrumb: [{ parent: 'Collaboration', label: 'Message Center' }],
        },
    },
    {
        path: '/schedulemeeting',
        name: 'schedulemeeting',
        component: () => import('./pages/ScheduleMeeting.vue'),
        meta: {
            breadcrumb: [{ parent: 'Collaboration', label: 'Schedule Meeting' }],
        },
    },
    {
        path: '/incidentalert',
        name: 'incidentalert',
        component: () => import('./pages/IncidentAlert.vue'),
    },
    {
        path: '/featurerequest',
        name: 'featurerequest',
        component: () => import('./pages/FeatureRequest.vue'),
        meta: {
            breadcrumb: [{ parent: 'Service', label: 'Feature Request' }],
        },
    },
    {
        path: '/termsofusage',
        name: 'termsofusage',
        component: () => import('./pages/TermsOfUsage.vue'),
        meta: {
            breadcrumb: [{ parent: 'Service', label: 'Terms of Usage' }],
        },
    },
    {
        path: '/helpcenter',
        name: 'helpcenter',
        component: () => import('./pages/HelpCenter.vue'),
        meta: {
            breadcrumb: [{ parent: 'Service', label: 'Help Center' }],
        },
    },
    {
        path: '/rfpquestionnaire',
        name: 'rfp',
        component: () => import('./utilities/RfpQuestionnaire.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'RFP' }],
        },
    },
    {
        path: '/user-management',
        name: 'User Management',
        component: () => import('./pages/UserManagement.vue'),
        meta: {
            breadcrumb: [{ parent: 'Service', label: 'User Management' }],
        },
    },
    {
        path: '/account-management',
        name: 'Account Management',
        component: () => import('./pages/AccountManagement.vue'),
        meta: {
            breadcrumb: [{ parent: 'Service', label: 'Account Management' }],
        },
    },
    {
        path: '/arena-management',
        name: 'Arena Management',
        component: () => import('./pages/ArenaManagement.vue'),
        meta: {
            breadcrumb: [{ parent: 'Service', label: 'Arena Management' }],
        },
    },

    // ===== Partner Matching Grid =====
    {
        path: '/matching',
        name: 'Partner Matching',
        component: () => import('./pages/PartnerMatching.vue'),
        meta: {
            breadcrumb: [{ label: 'Partner Matching' }],
        },
    },

    // ===== Outsourcing Readiness Assessment Admin =====
    {
        path: '/oras',
        name: 'Outsourcing Readiness Assessments',
        component: () => import('./pages/OutsourcingReadinessAssessment/ORAs.vue'),
        meta: {
            breadcrumb: [{ label: 'Outsourcing Readiness Assessments' }],
        },
    },
    {
        path: '/question-library',
        name: 'Question Library',
        component: () => import('./pages/QuestionBank.vue'),
        meta: {
            breadcrumb: [{ label: 'Question Library' }],
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const publicPages = ['login', 'register', 'landing'];
    const authRequired = !publicPages.includes(to.name);

    // alternate way
    // if (to.matched.some(record => record.meta.requiresAuth))

    if (authRequired == false) {
        next();
    } else {
        Auth.currentAuthenticatedUser()
            .then(() => {
                next(); // proceed to desired screen
            })
            .catch(() => {
                next('/login'); // send to login screen
            });
    }
});

export default router;
